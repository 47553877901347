export enum NetPromoterScoreStage {
  Onboarding = "onboarding",
  Offboarding = "offboarding",
}

export interface NetPromoterScorePayload {
  mid?: string;
  score: number;
  stage: NetPromoterScoreStage;
}

export interface NetPromoterScoreCanCreatePayload {
  stage: NetPromoterScoreStage;
}
