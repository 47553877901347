import { ExperienceUserMember } from "@a_team/models/dist/ExperienceObject";
import { Avatar, Spinner, Typography } from "@a_team/ui-components";
import { ToBuilderLocation } from "Locations";
import analytics from "analytics";
import { BUILDER_DISCOVERED } from "analytics/events";
import { getUserDisplayName } from "helpers/strings";
import useBuilderAvatar from "hooks/useBuilderAvatar";
import { useRoleCategories } from "queries/roleCategories/useRoleCategories";
import { useMemo } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Flex } from "views/Builder/components/Flex";

export const ProjectMember = ({
  member,
  onClick,
}: {
  member: ExperienceUserMember;
  onClick?: () => void;
}) => {
  const location = useLocation();
  const { push } = useHistory();

  const { id } = useParams<{ id: string }>();

  const { data: roleCategories, isLoading } = useRoleCategories();

  const avatar = useBuilderAvatar(member.profilePictureURL);

  const role = useMemo(() => {
    if (!member.memberRole || !roleCategories) {
      return "";
    }

    const role = roleCategories.find(
      (category) => category.cid === member.memberRole
    );

    if (!role) {
      return "";
    }

    return role.title;
  }, [member.memberRole, roleCategories]);

  if (isLoading) {
    return <Spinner size="md" />;
  }

  const handleClick = () => {
    push(ToBuilderLocation(member.uid));
    onClick && onClick();
    analytics.track(BUILDER_DISCOVERED, {
      name: "Builder Discovered",
      originalBuilderId: id,
      discoveredBuilderId: member.uid,
      location: location.pathname,
    });
  };

  return (
    <Flex direction="column" alignItems="flex-start" style={{ width: "182px" }}>
      <div style={{ paddingBottom: "8px" }}>
        <Avatar
          fill
          onClick={handleClick}
          size="md"
          src={avatar}
          style={{ cursor: "pointer" }}
        />
      </div>
      <Typography as="div" color="Grey@900" variant="textMedium">
        {getUserDisplayName(member)}
      </Typography>
      <Typography as="div" color="Grey@500" variant="textMedium">
        {role}
      </Typography>
    </Flex>
  );
};
