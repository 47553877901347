/** @jsxImportSource @emotion/react */

import { Avatar, Icon } from "@a_team/ui-components";
import { CSSObject, css } from "@emotion/react";
import { NavigationItem } from "components/Sidebar/Navigation/NavigationItem";
import { useWorkspaceAvatar } from "hooks/avatars/useWorkspaceAvatar";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import isMobile from "ismobilejs";
import { observer } from "mobx-react";
import { useWorkspaceSettings } from "queries/settings/useWorkspaceSettings";
import { useMemo } from "react";
import { useScreenClass } from "react-grid-system";
import { useRootStore } from "store";
import Sidebar from "../../Sidebar";
import AccountHeader from "./AccountHeader";
import ClientModeToggle from "./ClientModeToggle";
import MainNavigation from "./MainNavigation";
import UserTag from "./UserTag";

const cssRules: CSSRulesResolver<{
  isOpen: boolean;
  transparent?: boolean;
}> = ({ colors, isOpen, transparent }) => ({
  avatar: {
    padding: "8px 10px",
    margin: "10px 8px",
    height: 32,
    flexGrow: 0,
    flexShrink: 0,
    display: "flex",
    justifyContent: "space-between",
    borderRadius: 4,
    ...(isOpen && {
      backgroundColor: colors.Hannibal[200],
    }),
  },
  footer: {
    marginTop: "auto",
    flex: "0 0 auto",
  },
  iphone: {
    marginBottom: 80,
  },
  ipad: {
    marginBottom: 35,
  },
  menuIconMobileClosed: {
    width: 10,
    transform: "scale(0.4, 1)",
    color: transparent ? "white" : "inherit",
  },
  settingsContainer: {
    padding: "0 8px",
    backgroundColor: "#f4f5f7",
  },
});

const DefaultSidebar = ({
  transparent = false,
  avatarStyles,
}: {
  transparent?: boolean;
  avatarStyles?: CSSObject;
}) => {
  const {
    uiStore: {
      sidebarOpen,
      openSidebar,
      closeSidebar,
      isWorkspaceSelectorOpen,
    },
    accountsStore: { currentAccount },
  } = useRootStore();
  const { data } = useWorkspaceSettings();

  const styles = useCSSRulesWithTheme(cssRules, {
    isOpen: isWorkspaceSelectorOpen,
  });

  const iPad = isMobile(window.navigator).apple.tablet;
  const iPhone = isMobile(window.navigator).apple.phone;
  const screenClass = useScreenClass();
  const isSmallestScreen = useMemo(() => screenClass === "xs", [screenClass]);

  const handleCollapseExpand = () =>
    sidebarOpen ? closeSidebar() : openSidebar();

  const workspaceLogo = useWorkspaceAvatar(data?.logo);

  return (
    <Sidebar openInitially={sidebarOpen} transparent={transparent}>
      <div css={css(styles.avatar, avatarStyles)}>
        {isSmallestScreen && !sidebarOpen ? (
          <div
            onClick={openSidebar}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Avatar
              css={{ img: { objectFit: "cover", width: 32, height: 32 } }}
              src={workspaceLogo}
              size="md"
            />
            <Icon
              data-testing-id="open-sidebar"
              name="menu"
              css={styles.menuIconMobileClosed}
            />
          </div>
        ) : (
          <AccountHeader account={currentAccount} />
        )}
        {isSmallestScreen && sidebarOpen && (
          <Icon
            data-testing-id="close-sidebar"
            name="remove"
            onClick={closeSidebar}
            size="lg"
          />
        )}
      </div>
      <MainNavigation />
      <ClientModeToggle />
      <UserTag />
      <span
        css={css(
          styles.settingsContainer,
          iPhone && styles.iphone,
          iPad && styles.ipad
        )}
      >
        <NavigationItem
          id="collapse"
          label="Collapse"
          handleClick={handleCollapseExpand}
          iconName={sidebarOpen ? "collapseLeft" : "collapseRight"}
          enabled
          highlight
        />
      </span>
    </Sidebar>
  );
};

export default observer(DefaultSidebar);
