/** @jsxImportSource @emotion/react */

import { ButtonVariants, Tooltip, ButtonSizes } from "@a_team/ui-components";
import { css } from "@emotion/react";
import TrackButton from "components/TrackButton";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { FC, ReactElement, ReactNode } from "react";
import { useLocation } from "react-router-dom";
import { useRootStore } from "store";

type ExtendedButtonVariants = ButtonVariants | "normal";

const getCSSRules: CSSRulesResolver<{
  variant?: ExtendedButtonVariants;
  loading?: boolean;
}> = ({ colors, loading }) => ({
  root: {
    position: "relative",
    textWrap: "nowrap",
    width: "fit-content",

    "span:nth-of-type(2)": {
      visibility: loading ? "hidden" : "visible",
      width: loading ? 0 : "auto",
    },
    "&::after": {
      content: loading ? "attr(data-loading-text)" : "none",
      visibility: loading ? "visible" : "hidden",
      position: "relative",
    },
  },
  tooltip: {
    width: 248,
  },
  normal: {
    backgroundColor: colors.Grey[200],
    fontSize: 12,
    lineHeight: "18px",
    color: "black",
    border: "none",
    borderRadius: 12,
    height: 32,
    whiteSpace: "nowrap",
    padding: "0 12px",
  },
});

const ActionButton: FC<{
  onClick: () => void;
  txt?: string;
  disabled?: boolean;
  variant?: ExtendedButtonVariants;
  loading?: boolean;
  tooltip?: string | ReactElement;
  name?: string;
  size?: ButtonSizes;
  loadingTxt?: string;
  eventProps?: Record<string, any>;
  className?: string;
  children?: ReactNode;
}> = ({
  txt,
  onClick,
  disabled = false,
  variant = "secondary",
  loading = false,
  tooltip,
  name,
  size,
  loadingTxt,
  eventProps,
  className,
  children,
}) => {
  const {
    uiStore: { isMobile },
    accountsStore: { currentAccountId },
    userStore: { user },
  } = useRootStore();
  const styles = useCSSRulesWithTheme(getCSSRules, { variant, loading });
  const useVariant = variant === "normal" ? "secondary" : variant;
  const location = useLocation();
  const missionId = /^\/mission\/([^/]+)\/?/.exec(location.pathname)?.[1];

  const Button = (
    <TrackButton
      className={className}
      name={name || txt || ""}
      size={size ? size : isMobile ? "sm" : "md"}
      onClick={onClick}
      disabled={disabled}
      style={{ marginRight: 8 }}
      variant={useVariant}
      loading={loading}
      css={css(styles.root, variant === "normal" && styles.normal)}
      data-loading-text={loadingTxt || "Loading..."}
      eventProps={{
        flowV2: true,
        accountId: currentAccountId,
        missionSpecId: missionId,
        isAdmin: user?.isAdmin,
        ...eventProps,
      }}
    >
      {txt}
      {children}
    </TrackButton>
  );

  // Div is needed for Tooltip to work - who knows why
  return tooltip ? (
    <Tooltip
      css={styles.tooltip}
      text={tooltip}
      placement="bottom"
      maxWidth={248}
    >
      <div>{Button}</div>
    </Tooltip>
  ) : (
    Button
  );
};

export default ActionButton;
