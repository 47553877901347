/** @jsxImportSource @emotion/react */

import { UserId } from "@a_team/models/dist/UserObject";
import { Typography } from "@a_team/ui-components";
import { CSSObject } from "@emotion/react";
import useCheckAccessModal from "components/AccessModalProvider/useCheckAccessModal";
import { GateBanner } from "components/Auth/GateBanner";
import { useGlobalModals } from "components/GlobalModals";
import { BuildersReviewStage } from "components/Proposals";
import { useProposalContext } from "components/Proposals/ReviewBuilders/ProposalContext";
import useCanAccessMission from "hooks/access/useCanAccessMission";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { useMinBreakpoint } from "hooks/useMinBreakpoint";
import { DateTime } from "luxon";
import { observer } from "mobx-react";
import {
  ProposalObject,
  ProposalRole,
  ProposalRoleBuilder,
} from "models/Proposal";
import { useAccount } from "queries/accounts/useAccount";
import { useAdvisorForProposal } from "queries/proposals/useAdvisorForProposal";
import { useEffect, useMemo, useState } from "react";
import { useRootStore } from "store";
import logoOverlayImage from "views/Builder/assets/a-logo.svg";
import { Flex } from "views/Builder/components/Flex";
import { AdvisorTooltip } from "views/Mission/Proposals/Native/AdvisorTooltip";
import { Footer } from "views/Mission/Proposals/Native/Footer";
import FullBuilder from "views/Mission/Proposals/Native/FullBuilder";
import { FullBuilderCard } from "views/Mission/Proposals/Native/FullBuilderCard";
import Header from "views/Mission/Proposals/Native/Header";
import MobileFooter from "views/Mission/Proposals/Native/MobileFooter";
import NoAccessModalContent from "views/Mission/Proposals/Native/NoAccessModalContent";
import RoleCard from "views/Mission/Proposals/Native/RoleCard";
import { ScrollToTopTooltip } from "views/Mission/Proposals/Native/ScrollToTopTooltip";
import { Tabs } from "views/Mission/Proposals/Native/Tabs";
import { TeamNarratives } from "views/Mission/Proposals/Native/TeamNarratives";

const getCSSRules: CSSRulesResolver<{
  multipleRoles: boolean;
  teamProposal: boolean;
  footerHeight: number;
}> = ({ colors, breakpoints, multipleRoles, teamProposal, footerHeight }) => ({
  container: {
    height: "100vh",
    overflowY: "auto",
    position: "relative",
  } as CSSObject,
  content: {
    paddingLeft: 15,
    paddingRight: 15,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      margin: "0 auto",
      maxWidth: NATIVE_PROPOSAL_MAX_WIDTH,
      paddingTop: multipleRoles ? 0 : 60,
    },
  },
  title: {
    fontSize: "30px",
    fontWeight: 500,
    lineHeight: "38px",
    paddingBottom: 4,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      fontSize: "38px",
      lineHeight: "48px",
    },
  },
  intro: {
    paddingTop: 15,
    height: multipleRoles && !teamProposal ? 184 : "auto",
    marginLeft: -15,
    marginRight: -15,
    paddingBottom: 40,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      height: multipleRoles ? 184 : "auto",
      paddingTop: 34,
      paddingBottom: multipleRoles ? 0 : 64,
    },
  },
  link: {
    "&&": {
      color: "Grey@0",
    },
  },
  roleDetails: {
    paddingBottom: 48,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      paddingTop: multipleRoles ? 80 : 0,
      paddingBottom: 80,
    },
  },
  roleDetailsTitle: {
    paddingBottom: 16,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      paddingBottom: 24,
    },
    color: multipleRoles ? colors.Grey[900] : colors.Grey[0],
  },
  builderDetails: {
    paddingBottom: 80,
  },
  builderDetailsTitle: {
    paddingBottom: 40,
  },
  section: {
    paddingBottom: 48,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      paddingBottom: 80,
    },
  },
  sectionTitle: {
    paddingBottom: 24,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      paddingBottom: 40,
    },
    text: {
      fontSize: 24,
      lineHeight: "30px",
      fontWeight: 500,
      [`@media (min-width: ${breakpoints.sm}px)`]: {
        fontSize: 30,
        lineHeight: "38px",
      },
    },
  },
  cardGrid: {
    display: "grid",
    gridTemplateColumns: "1fr",
    alignItems: "flex-start",
    gap: 16,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
    },
  },
  sm: {
    display: "none",
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      display: "block",
    },
    hide: {
      [`@media (min-width: ${breakpoints.sm}px)`]: {
        display: "none",
      },
    },
  },
  mobileFooter: {
    bottom: 0,
    height: footerHeight,
    left: 0,
    position: "fixed",
    right: 0,
    zIndex: 9999,
  } as CSSObject,
  toolTips: {
    display: "none",
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      position: "fixed",
      right: 60,
      bottom: 32,
      zIndex: 9999,
      display: "flex",
      flexDirection: "column",
      gap: 8,
      alignItems: "center",
    },
  } as CSSObject,
});

const HeaderBackground = ({ height = 412 }) => {
  const styles: CSSObject = useCSSRulesWithTheme(({ colors }) => ({
    container: {
      backgroundColor: colors.Grey[100],
      height,
      position: "absolute",
      width: "100%",
      zIndex: -1,
      top: 0,
      left: 0,
      overflow: "hidden",
    },
    bg: {
      backgroundImage: `url(${logoOverlayImage}), linear-gradient(to right, ${colors.Hannibal[600]}, ${colors.Hannibal[400]})`,
      backgroundPosition: "calc(50% + 100px) center, center center",
      backgroundRepeat: "no-repeat, no-repeat",
      backgroundSize: "auto 1500px, 100%",
      height: "100%",
    },
  }));

  return (
    <div css={styles.container}>
      <div css={styles.bg} />
    </div>
  );
};

export const NATIVE_PROPOSAL_MAX_WIDTH = 1136;

const NativeProposal = ({
  proposal,
  onAdminReviewProposalClick,
  onReviewProposalClick,
  onAdminShareProposalClick,
}: {
  proposal: ProposalObject;
  onAdminReviewProposalClick?: (isApproval: boolean) => void;
  onReviewProposalClick?: (stage?: BuildersReviewStage) => void;
  onAdminShareProposalClick?: () => void;
}) => {
  const {
    userStore: { user: currentUser, isAppInAdminMode },
    authStore: { isLoggedIn },
  } = useRootStore();
  const { data: teamAdvisor } = useAdvisorForProposal(proposal.id);
  const {
    setSelectedBuilderIds,
    setSelectedInterviewee,
    setInterviewModalMode,
  } = useProposalContext();
  const { toggleSchedulingModal } = useGlobalModals();
  const account = useAccount(proposal.accountId);

  const { toggleInterviewModal } = useGlobalModals();

  // Get total builders in proposal
  const totalBuilders = proposal.roles.reduce(
    (total, role) => total + role.builders.length,
    0
  );

  // If only one builder per role use team/narrative variant
  const teamProposal =
    totalBuilders > 1 &&
    proposal.roles.every((role) => role.builders.length === 1);

  const multipleRoles = proposal.roles.length > 1;
  const { teamNarratives, teamBlurb } = proposal;

  const [currentRole, setCurrentRole] = useState<ProposalRole | undefined>(
    undefined
  );

  useEffect(() => {
    if ((teamProposal || proposal.isSampleProposal) && currentRole) {
      return setCurrentRole(undefined);
    }
    if (!teamProposal && !currentRole) {
      return setCurrentRole(proposal.roles[0]);
    }
    if (currentRole) {
      const role = proposal.roles.find((role) => role.rid === currentRole.rid);
      if (role) {
        setCurrentRole(role);
      }
    }
  }, [proposal]);

  const styles = useCSSRulesWithTheme(getCSSRules, {
    multipleRoles,
    teamProposal,
    footerHeight: isAppInAdminMode && proposal.publicUntil ? 122 : 74,
  });

  const proposalTitle = useMemo(() => {
    if (proposal.isSampleProposal) {
      return `${proposal.projectName} Sample Proposal`;
    }

    if (currentRole && proposal.roles.length === 1) {
      return `${currentRole.roleCategory?.title} Proposal`;
    }

    return `${account.data?.workspace.name ?? "A.Team"} Team Proposal`;
  }, [currentRole, account]);

  const proposalDescription = (
    <div>
      {`Created on ${DateTime.fromISO(proposal.createdAt.toString()).toFormat(
        "LLL dd, yyyy"
      )}`}
      {teamAdvisor && !proposal.isSampleProposal && (
        <>
          {` by `}
          <a href={`mailto:${teamAdvisor?.email}`} target={"blank"}>
            <Typography variant="textMedium" color="Grey@0">
              {teamAdvisor?.fullName}
            </Typography>
          </a>
          {proposal.createdBy && (
            <>
              {" and "}
              <a
                href={`mailto:${proposal.missionSpecAuthor?.email}`}
                target={"blank"}
              >
                <Typography variant="textMedium" color="Grey@0">
                  {proposal.createdBy.fullName}
                </Typography>
              </a>
            </>
          )}
        </>
      )}
    </div>
  );

  const cardsSectionTitle = useMemo(() => {
    if (teamProposal || proposal.isSampleProposal) {
      return `Meet your A.Team`;
    }

    if (!currentRole || currentRole.builders.length <= 1) {
      return "Meet your builder";
    }

    return `Meet your recommended ${currentRole.roleCategory?.title.toLocaleLowerCase()}s`;
  }, [currentRole, proposal.isSampleProposal]);

  const isDesktop = useMinBreakpoint("sm");

  const headerBackgroundHeight = useMemo(() => {
    if (isDesktop) {
      if (!teamProposal && multipleRoles) {
        return 282;
      }
      return 412;
    }

    // On mobile
    if (!teamProposal && multipleRoles) {
      return 263;
    }

    return 340;
  }, [isDesktop, teamProposal, multipleRoles]);

  const rolesToShow = currentRole ? [currentRole] : proposal.roles;
  const buildersToShow = rolesToShow.reduce(
    (builders, role) => [
      ...builders,
      ...role.builders.map((builder) => ({ ...builder, rid: role.rid })),
    ],
    [] as Array<ProposalRoleBuilder & { rid: string }>
  );

  const { checkAndHandlePermission } = useCheckAccessModal();
  const hasMissionReadAccess = useCanAccessMission(
    "read",
    proposal?.missionSpecId
  );

  const handleSelectForInterview = async (builderId: UserId) => {
    const hasPermission = await checkAndHandlePermission(
      {
        title: "Request access",
        content: <NoAccessModalContent />,
        forMissionSpecId: proposal?.missionSpecId,
      },
      () => hasMissionReadAccess
    );

    if (hasPermission) {
      setSelectedBuilderIds([builderId]);

      setInterviewModalMode("single");
      setSelectedInterviewee(
        buildersToShow.find((builder) => builder.uid === builderId)
      );
      toggleInterviewModal(true);
    }
  };

  const enableInteractions = useMemo(() => {
    return !!currentUser && !!(proposal.isShared || proposal.isSampleProposal);
  }, [currentUser, proposal]);

  let footerPaddingBottom = 72;
  if (isAppInAdminMode && proposal.publicUntil) {
    footerPaddingBottom = 119;
  } else if (!isLoggedIn) {
    // showing gate banner
    footerPaddingBottom = 227;
  }
  const currentRoleId =
    proposal.rolesGroupedBy === "rid"
      ? currentRole?.rid
      : currentRole?.roleCategory?.cid;

  return (
    <div css={styles.container} data-id="layout-container">
      <Header
        pageTitle={proposalTitle}
        disabled={false}
        enableInteractions={enableInteractions}
        onAdminReviewProposalClick={onAdminReviewProposalClick}
        onAdminShareProposalClick={onAdminShareProposalClick}
        onClientReviewProposalClick={onReviewProposalClick}
        proposal={proposal}
      />
      <HeaderBackground height={headerBackgroundHeight} />
      <div css={styles.content}>
        <div css={styles.intro}>
          <Flex
            direction="column"
            justifyContent="space-between"
            alignItems="flex-start"
            style={{ height: "100%" }}
          >
            <div style={{ paddingLeft: 15, paddingRight: 15 }}>
              <Flex
                direction="column"
                alignItems="flex-start"
                style={{ height: "100%" }}
              >
                <Typography
                  variant="textMedium"
                  color="Grey@0"
                  css={styles.title}
                >
                  {proposalTitle}
                </Typography>
                <Typography
                  variant="textMedium"
                  component="div"
                  color="Hannibal@200"
                >
                  {proposalDescription}
                </Typography>
              </Flex>
            </div>
            {!teamProposal && multipleRoles && currentRoleId && (
              <div style={{ width: "100%" }}>
                <Tabs
                  roles={proposal.roles}
                  onTabSelect={(roleCategoryId) => {
                    const newRoleSelected = proposal.roles.find((role) => {
                      const roleId =
                        proposal.rolesGroupedBy === "rid"
                          ? role.rid
                          : role.roleCategory.cid;
                      return roleId === roleCategoryId;
                    });
                    setCurrentRole(newRoleSelected!);
                  }}
                  currentRoleCategoryId={currentRoleId}
                  groupedBy={proposal.rolesGroupedBy || "roleCategory"}
                />
              </div>
            )}
          </Flex>
        </div>
        {!teamProposal && !proposal.isSampleProposal && currentRole && (
          <div css={styles.roleDetails}>
            <div css={styles.roleDetailsTitle}>
              <Typography
                variant="textExtraLarge"
                css={styles.sectionTitle.text}
              >
                Role details
              </Typography>
            </div>
            <RoleCard role={currentRole} proposal={proposal} />
          </div>
        )}
        <section css={styles.section}>
          <div css={styles.sectionTitle}>
            <Typography
              variant="textExtraLarge"
              color={
                teamProposal || proposal.isSampleProposal
                  ? "Grey@0"
                  : "Grey@900"
              }
              css={styles.sectionTitle.text}
            >
              {cardsSectionTitle}
            </Typography>
          </div>
          <div css={styles.cardGrid}>
            {buildersToShow.map((builder) => {
              const role = proposal.roles.find(
                (role) => role.rid === builder.rid
              );
              if (!role) {
                return null; // Skip rendering if builder's role is not found in proposal roles
              }
              return (
                <FullBuilderCard
                  currency={proposal.currency}
                  key={`proposal--builder--card--${builder.uid}`}
                  builder={builder}
                  role={role}
                  onSelectForInterview={handleSelectForInterview}
                  enableInteractions={enableInteractions}
                  isSampleProposal={proposal.isSampleProposal}
                  proposalId={proposal.id}
                  isLoggedOut={!isLoggedIn}
                />
              );
            })}
          </div>
        </section>
        {(teamNarratives ?? []).length > 0 && (
          <TeamNarratives
            blurb={teamBlurb}
            builders={buildersToShow}
            teamNarratives={teamNarratives!}
          />
        )}
        <section css={styles.section}>
          <div css={styles.sectionTitle}>
            <Typography
              variant="textExtraLarge"
              component="div"
              style={{
                paddingBottom: 4,
              }}
              css={styles.sectionTitle.text}
            >
              {proposal.isSampleProposal
                ? "Sample A.Teamers for you"
                : teamProposal || !currentRole
                ? "Get to know your team"
                : `${currentRole.roleCategory?.title}s for your team`}
            </Typography>
            {!proposal.isSampleProposal && (
              <Typography variant="textLarge" component="div">
                {teamProposal
                  ? "Explore your team's top skills, why they're a match for this mission, and why each team member is excited to build with you."
                  : "Top recommendations for your team, formulated with the help of A.Team's proprietary TeamGraph AI."}
              </Typography>
            )}
          </div>
          {rolesToShow.map((role, i) => (
            <BuildersForRole
              enableInteractions={enableInteractions}
              key={`proposal--builders--role--${i}`}
              onSelectForInterview={handleSelectForInterview}
              proposal={proposal}
              role={role}
              isLoggedOut={!isLoggedIn}
            />
          ))}
        </section>
      </div>
      <Footer paddingBottom={footerPaddingBottom} />
      <div css={[styles.sm.hide]}>
        <MobileFooter
          disabled={false}
          onAdminReviewProposalClick={onAdminReviewProposalClick}
          onClientReviewProposalClick={onReviewProposalClick}
          proposal={proposal}
        />
      </div>
      {enableInteractions && (
        <div css={[styles.toolTips]}>
          <ScrollToTopTooltip />
          {!proposal.isSampleProposal && (
            <AdvisorTooltip onClick={() => toggleSchedulingModal()} />
          )}
        </div>
      )}
      {proposal.isSampleProposal && (
        <GateBanner
          title={
            proposal.createdBy?.fullName
              ? `${proposal.createdBy.fullName} shared a Sample Proposal with you.`
              : "A.Team shared a Sample Proposal with you."
          }
          subtitle="Join A.Team to book interviews and discover more talent."
        />
      )}
    </div>
  );
};

const BuildersForRole = ({
  enableInteractions = false,
  onSelectForInterview,
  proposal,
  role,
  isLoggedOut,
}: {
  enableInteractions: boolean;
  onSelectForInterview: (userId: string) => void;
  proposal: ProposalObject;
  role: ProposalRole;
  isLoggedOut?: boolean;
}) => {
  const styles = useCSSRulesWithTheme(() => ({
    container: {
      paddingBottom: 180,
      "&:last-child": {
        paddingBottom: 0,
      },
    },
  }));

  return (
    <>
      {role.builders.map((builder) => (
        <div key={`proposal--builder--${builder.uid}`} css={styles.container}>
          <FullBuilder
            onSelectForInterview={onSelectForInterview}
            proposal={proposal}
            builder={builder}
            role={role}
            enableInteractions={enableInteractions}
            isSampleProposal={proposal.isSampleProposal}
            isLoggedOut={isLoggedOut}
          />
        </div>
      ))}
    </>
  );
};

export default observer(NativeProposal);
