/** @jsxImportSource @emotion/react */

import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { observer } from "mobx-react-lite";
import { Button, Modal } from "@a_team/ui-components";
import { useLayoutEffect, useRef, useState } from "react";
import { useCreateNetPromoterScore } from "queries/netPromoterScore/useCreateNetPromoterScore";
import { NetPromoterScoreStage } from "models/NetPromoterScore";

export enum NetPromoterScoreType {
  Inline = "inline",
  Modal = "modal",
}

interface NetPromoterScoreProps {
  type: NetPromoterScoreType;
  score: number | null;
  setScore: (score: number | null) => void;
  missionId?: string;
}

const useStyles = () => {
  return useCSSRulesWithTheme(({ colors, breakpoints }) => ({
    inlineTitle: {
      fontSize: 18,
      fontWeight: 400,
    },
    modalTitle: {
      fontSize: 18,
      fontWeight: 400,
      paddingRight: 8,
    },
    scoreWrapperForScroll: {
      [`@media (max-width: ${breakpoints.sm}px)`]: {
        width: "100%",
        maxWidth: "100%",
        overflowX: "hidden" as const,
      },
    },
    scoreWrapper: {
      display: "flex",
      alignItems: "center",
      gap: 8,

      paddingBottom: 8,

      width: "100%",
    },
    scoresContainer: {
      display: "flex",
      minWidth: 327,
      gap: 3,
      width: "100%",
      [`@media (max-width: ${breakpoints.sm}px)`]: {
        overflowX: "auto" as const,
      },
    },
    scoreItem: {
      marginTop: 12,
      minWidth: 40,
      minHeight: 40,
      flex: 1,
      borderRadius: 8,
      border: `1px solid ${colors.Grey[200]}`,
      backgroundColor: colors.Grey[0],
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      transition: "all 0.2s ease",
      "&:hover": {
        backgroundColor: colors.Hannibal[200],
        color: colors.Grey[0],
      },
    },
    scoreItemSelected: {
      backgroundColor: colors.Hannibal[600],
      border: `1px solid ${colors.Hannibal[600]}`,
      color: colors.Grey[0],
      "&:hover": {
        backgroundColor: colors.Hannibal[600],
      },
    },
    legends: {
      display: "flex",
      justifyContent: "space-between",
    },
    legend: {
      marginTop: 12,
      marginBottom: 24,
      fontSize: 12,
      color: colors.Grey[500],
    },
    buttonWrapper: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: 24,
    },
    modal: {
      "& > div": {},
    },
    submitButton: {
      padding: "10px 40px",
    },
  }));
};

const NetPromoterScore = ({
  type,
  score,
  setScore,
  missionId,
}: NetPromoterScoreProps) => {
  const styles = useStyles();
  const scoresContainerRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    setTimeout(() => {
      if (scoresContainerRef.current) {
        scoresContainerRef.current.scrollLeft =
          scoresContainerRef.current.scrollWidth;
      }
    }, 100);
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(
    type === NetPromoterScoreType.Modal
  );

  const { mutateAsync: mutateAsyncNetPromoterScore, isLoading } =
    useCreateNetPromoterScore();

  const handleModalSubmit = () => {
    if (score) {
      mutateAsyncNetPromoterScore({
        score,
        stage: NetPromoterScoreStage.Offboarding,
        mid: missionId,
      });
      setIsModalOpen(false);
    }
  };

  const scoreSelector = (type: NetPromoterScoreType) => {
    return (
      <div>
        <div
          css={
            type === NetPromoterScoreType.Modal
              ? styles.modalTitle
              : styles.inlineTitle
          }
        >
          How likely is it that you would recommend A.Team to a friend or
          colleague?
        </div>
        <div css={styles.scoreWrapperForScroll}>
          <div css={styles.scoreWrapper}>
            <div css={styles.scoresContainer} ref={scoresContainerRef}>
              {Array.from({ length: 11 }, (_, index) => (
                <button
                  key={index}
                  css={[
                    styles.scoreItem,
                    score === index && styles.scoreItemSelected,
                  ]}
                  onClick={() => {
                    setScore(index);
                  }}
                >
                  {index}
                </button>
              ))}
            </div>
          </div>
        </div>
        <div css={styles.legends}>
          <div css={styles.legend}>Not likely</div>
          <div css={styles.legend}>Extremely likely</div>
        </div>
      </div>
    );
  };

  if (type === NetPromoterScoreType.Inline) {
    return scoreSelector(type);
  } else if (type === NetPromoterScoreType.Modal) {
    return (
      <div>
        <Modal
          isOpen={isModalOpen}
          shouldCloseOnOverlayClick={true}
          hideCloseButton={false}
          onClose={() => setIsModalOpen(false)}
          zIndex={10001}
          css={styles.modal}
          variant="slideUp"
        >
          {scoreSelector(type)}
          <div css={styles.buttonWrapper}>
            <Button
              onClick={handleModalSubmit}
              disabled={score === null || isLoading}
              loading={isLoading}
              css={styles.submitButton}
            >
              Submit
            </Button>
          </div>
        </Modal>
      </div>
    );
  }

  return <></>;
};

export default observer(NetPromoterScore);
