/** @jsxImportSource @emotion/react */

import { Avatar, Typography } from "@a_team/ui-components";
import { useReview } from "queries/userReviews/useReview";
import Skeleton from "react-loading-skeleton";
import { Actions } from "../Actions";
import { useRemainingMissionReviews } from "queries/userReviews/useRemainingMissionReviews";
import StarsConfetti from "./../../assets/stars.svg";
import { RemainingReviews } from "./RemainingReviews";
import NetPromoterScore from "views/NetPromoterScore";
import { NetPromoterScoreType } from "views/NetPromoterScore";
import { Flags } from "configs/featureFlags";
import { useCanCreateNetPromoterScore } from "queries/netPromoterScore/useCanCreateNetPromoterScore";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { NetPromoterScoreStage } from "models/NetPromoterScore";
import { useMemo, useState } from "react";

interface Props {
  reviewId?: string;
  onReviewClick: (id: string, rating: number) => void;
  onNext?: () => void;
  onBack?: () => void;
}

export const Thanks = (props: Props) => {
  const { onNext, reviewId, onReviewClick } = props;
  const { data: review } = useReview(reviewId);
  const { data: remainingReviews } = useRemainingMissionReviews(reviewId);
  const remainingBuildersShown =
    remainingReviews && remainingReviews.length > 0;

  const { data: canCreateNetPromoterScore } = useCanCreateNetPromoterScore({
    stage: NetPromoterScoreStage.Offboarding,
  });
  const { flagEnabled: netPromoterScoreEnabled } = useFeatureFlag(
    Flags.NetPromoterScore
  );
  const showNetPromoterScore = useMemo(() => {
    return !!(netPromoterScoreEnabled && canCreateNetPromoterScore);
  }, [netPromoterScoreEnabled, canCreateNetPromoterScore]);
  const [score, setScore] = useState<number | null>(null);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 24,
      }}
    >
      {review ? (
        <>
          <div style={{ position: "relative" }}>
            <img src={StarsConfetti} />
            <Avatar
              style={{
                position: "absolute",
                top: 35,
                left: 70,
              }}
              src={review.toUser?.profilePicture}
              size={"xl"}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="textLarge" component="div">
              Thanks for sharing your feedback for{" "}
              {review.toUser?.fullName || "this builder"}
            </Typography>
            <Typography variant="textMedium" weight={"regular"}>
              Your feedback helps us to to provide you with better matches on
              A.Team.
            </Typography>
          </div>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 8,
          }}
        >
          <Skeleton circle height={100} width={100} />
          <Skeleton height={20} width={300} />
          <Skeleton height={10} width={400} />
        </div>
      )}
      {remainingBuildersShown && (
        <RemainingReviews
          reviews={remainingReviews}
          onReviewClick={onReviewClick}
        />
      )}
      <Actions
        onNext={onNext}
        submitText={
          remainingBuildersShown
            ? "or discover more builders"
            : "Discover more builders"
        }
      />
      {showNetPromoterScore && (
        <NetPromoterScore
          type={NetPromoterScoreType.Modal}
          setScore={setScore}
          score={score}
          missionId={review?.missionId}
        />
      )}
    </div>
  );
};
