import { useMutation } from "@tanstack/react-query";
import { useRootStore } from "store";
import { createNetPromoterScore } from "services/netPromoterScore";
import { NetPromoterScorePayload } from "models/NetPromoterScore";

export const useCreateNetPromoterScore = () => {
  const {
    authStore,
    uiStore: { setApiErrorToast },
  } = useRootStore();

  return useMutation({
    mutationFn: (payload: NetPromoterScorePayload) => {
      return createNetPromoterScore(authStore, payload);
    },
    onError: (error) =>
      setApiErrorToast(error, "Failed to create net promoter score"),
  });
};
