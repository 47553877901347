import { useQuery } from "@tanstack/react-query";
import { useQueryKeySpecificity } from "hooks/useQueryKeyAuth";
import { useRootStore } from "store";
import queryKeys from "../keys";
import { NetPromoterScoreStage } from "models/NetPromoterScore";
import { canCreateNetPromoterScore } from "services/netPromoterScore";

export const useCanCreateNetPromoterScore = ({
  stage,
}: {
  stage: NetPromoterScoreStage;
}) => {
  const {
    authStore,
    uiStore: { setApiErrorToast },
  } = useRootStore();

  const queryKeySpecificity = useQueryKeySpecificity({
    uid: authStore.tokenPayload?.uid,
    stage,
  });

  const { queryKey } =
    queryKeys.netPromoterScore.canCreate(queryKeySpecificity);

  return useQuery({
    queryKey,
    enabled: authStore.isLoggedIn,
    queryFn: () => canCreateNetPromoterScore(authStore, { stage }),
    onError: (error) => {
      setApiErrorToast(
        error,
        "Failed determine if you can create a net promoter score"
      );
    },
  });
};
