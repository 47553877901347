import MissionObject, {
  MissionApplyStatus,
  MissionManager,
  MissionStatus,
} from "@a_team/models/dist/MissionObject";
import {
  TalentCategory,
  TalentSkill,
} from "@a_team/models/dist/TalentCategories";
import { TalentIndustry } from "@a_team/models/dist/TalentIndustry";
import { TeamPulse } from "@a_team/models/dist/TeamPulse";
import {
  RegisteredUserObject,
  UserCardObject,
} from "@a_team/models/dist/UserObject";
import { CompanyObject } from "./ClientCompany";
import { MissionLink } from "@a_team/models/dist/MissionObject";
import { omit } from "lodash";
import { ProposalIdSummary } from "./Proposal";
import { BasicUserObject } from "@a_team/models/dist/UserObject";
import {
  MissionRoleId,
  MissionRoleStatus,
  ClientRoleQuestion,
} from "@a_team/models/dist/MissionRole";
import { WorkingHoursSchema } from "@a_team/models/dist/WorkingHoursObject";
import { ProposalId } from "@a_team/models/dist/ProposalObject";
import RoleCategory from "models/RoleCategory";
import { DateISOString } from "@a_team/models/dist/misc";
import { ObjectId } from "bson";

export type Nullable<T> = T | null;
export interface InternalTeamMemberObject {
  email: string;
  fullName: string;
}

export enum RoleRevisionApprovalStatus {
  Requested = "Requested",
  Reviewing = "Reviewing",
  Approved = "Approved",
  Denied = "Denied",
}

export enum SpecBridgeType {
  search = "search",
  starred = "starred",
}

export interface Role {
  // PRIVATE FIELDS FOR ADMINS - never to be exposed to clients
  _PRIVATE_BUILDER_RATE?: number;
  _PRIVATE_ROLE_MARGIN?: number;

  // Public fields
  rid: string;
  revisionId?: string;
  category?: RoleCategory;
  description: string;
  headline?: string;
  locations?: string[] | null;
  internalTeamMember?: InternalTeamMemberObject;
  isLead?: boolean;
  isInternal?: boolean;
  minimumCommitment?: number;
  note?: string;
  requiredSkills: Array<TalentSkill>;
  preferredSkills: Array<TalentSkill>;
  tags: Array<TalentCategory>;
  live?: boolean;
  user?: UserCardObject;
  avgHoursPerWeek?: number;
  clientDisplayRate?: number;
  approvalStatus?: RoleRevisionApprovalStatus;
  isNew?: boolean;
  rejectionCategory?: RoleRevisionRejectionCategory;
  status?: MissionRoleStatus;
  customQuestions?: ClientRoleQuestion[];
  [key: string]: unknown;
  clientRateMin?: number;
  clientRateMax?: number;
  createdAt?: Date;
  createdBy?: string;
  clientMonthlyDisplayRate?: number;
  isFullTimeRetainer?: boolean;
  pendingReview?: string;
}

export enum ApprovalStatus {
  Requested = "Requested",
  Reviewing = "Reviewing",
  Approved = "Approved",
  Denied = "Denied",
}

export enum RoleRevisionRejectionCategory {
  DoesNotBelong = "The role doesn't belong to this mission",
  Mistake = "The team extension request was created by mistake",
  Outdated = "The scope of the mission changed and no longer need this role",
  NoBudget = "There's no budget or funding for this role",
  Other = "There's other reasons",
}

export type MissionSpecStatus =
  | "spec"
  | "formation"
  | "proposal"
  | "confirmed"
  | "published"
  | "declined";

export enum MissionCardStatus {
  Spec = "Spec",
  Formation = "Formation",
  Proposal = "Proposal",
  Confirm = "Confirm",
  Running = "Running",
  Ended = "Ended",
  Archived = "Archived",
  Canceled = "Canceled",
  Declined = "Declined",
  RunningWithProposal = "RunningWithProposal",
}

export interface Comment {
  text: string;
  mission: string;
  id?: string;
  updatedAt?: Date;
  createdAt?: Date;
  author?: UserCardObject;
}

export enum MissionPlatformType {
  mobileApp = "Mobile app",
  webPlatform = "Web platform",
  analytics = "Analytics",
  uxResearch = "UX research",
}

export interface MissionSpecErrors {
  title?: boolean;
  roles?: boolean;
  description?: boolean;
  startDate?: boolean;
}

export type AttachedLink = MissionLink;

export interface ExtendedMissionManager extends MissionManager {
  user: BasicUserObject & { email: string };
}

export interface MissionSpecAssets {
  proposals: Array<ProposalId>;
  roleRevisions: Array<MissionRoleId>;
}

export default interface MissionSpec {
  accountId?: string;
  attachedLinks?: Array<AttachedLink>;
  author?: RegisteredUserObject;
  clientCompany?: CompanyObject;
  collaborators?: Array<UserCardObject>;
  companyDescription?: string;
  createdAt?: Date;
  description?: string;
  duration?: string;
  hubspotDealId?: string;
  icon?: string;
  id?: string;
  industry?: TalentIndustry;
  lastModifier?: RegisteredUserObject;
  logo?: string;
  mid?: string;
  mission?: MissionObject & {
    accountId?: string;
    teamPulse?: TeamPulse;
    updatedAt?: DateISOString;
    mainManagerUsername?: string;
  };
  pendingRoles?: Array<Role>;
  platformId?: string;
  proposalIds?: ProposalIdSummary;
  roles: Array<Role>;
  startDate?: Date;
  status: MissionSpecStatus;
  title?: string;
  updatedAt?: Date;
  videoURL?: string;
  workingHours?: WorkingHoursSchema;
  workingHoursNumberOfMinutesOverlap?: number;
  metadata?: Record<string, any>;
  [key: string]: unknown;
}

export type CollaboratorObject = UserCardObject | NewCollaborator;
export type LocalCollaborators = Array<CollaboratorObject>;

export interface NewCollaborator {
  _id: string;
  email: string;
  firstName: string;
  lastName: string;
  companyName?: string;
  companyRole?: string;
  companyWebsite?: string;
}

export type MissionRouteParams = {
  mid: string;
};

export function toRoleDbRefs(role: Role, v2 = false): unknown /*TODO: typing*/ {
  const data = omit(role, [
    "approvalStatus",
    ...(v2
      ? ["rid", "avgHoursPerWeek", "autoPublish", "_PRIVATE_ROLE_MARGIN", "_id"]
      : []),
  ]);

  return {
    ...data,
    ...(v2 && {
      id: role.rid || new ObjectId().toHexString(),
      locations: role.locations || [],
    }),
    requiredSkills:
      role.requiredSkills && role.requiredSkills.length > 0
        ? role.requiredSkills.map(({ id }) => id)
        : [],
    preferredSkills:
      role.preferredSkills && role.preferredSkills.length > 0
        ? role.preferredSkills.map(({ id }) => id)
        : [],
    ...(!v2 && {
      tags:
        role.tags && role.tags.length > 0 ? role.tags.map(({ id }) => id) : [],
    }),
    category: role.category && role.category.cid,
    user: role.user && role.user.uid,
    createdAt: role.createdAt,
  };
}

export function toMissionDbRefs(
  mission: MissionSpec,
  previousMissionState?: MissionSpec,
  v2 = false
): unknown {
  const {
    title,
    description,
    timezone,
    status,
    website,
    icon,
    startDate,
    duration,
    attachedLinks,
    videoURL,
    platformId,
    companyDescription,
    logo,
    updatedAt,
    override = false,
    version = 2,
    workingHours,
    workingHoursNumberOfMinutesOverlap,
    mission: specMission,
    metadata,
  } = mission;

  return {
    title,
    description,
    timezone,
    status,
    website,
    duration,
    videoURL,
    companyDescription,
    logo,
    startDate: startDate && new Date(startDate),
    author: mission.author?.uid || mission.authorId,
    industry: mission.industry && mission.industry.id,
    roles: mission.roles
      ?.filter(({ category }) => !!category)
      .map((role) => toRoleDbRefs(role, v2)),
    clientCompany:
      mission.clientCompany && mission.clientCompany.id
        ? mission.clientCompany.id
        : undefined,
    icon,
    attachedLinks,
    platformId,
    collaborators:
      mission.collaborators && mission.collaborators?.length > 0
        ? mission.collaborators.map(({ uid }) => uid)
        : [],
    lastModifier: mission.lastModifier && mission.lastModifier.uid,
    updatedAt,
    missionUpdatedAt: specMission?.updatedAt || specMission?.publishedAt,
    override,
    version,
    workingHours,
    workingHoursNumberOfMinutesOverlap,
    previousMissionState,
    metadata,
  };
}

/* Transforms the prisma mission spec object to the mission spec object */
export const toMissionSpec = (missionSpec: MissionSpec): MissionSpec => {
  return {
    ...missionSpec,
    mid: missionSpec.id,
    roles: missionSpec.roles?.map((role) => ({
      ...role,
      rid: role.id as string,
    })),
  };
};

// If this needs to change, see the corresponding model on the backend
export interface MissionSpecCardRole {
  status: MissionRoleStatus;
  category?: {
    id: string;
    name: string;
    title: string;
    group: string;
  };
  user?: null | {
    uid: string;
    fullName: string;
    profilePictureURL?: string;
  };
}
export interface MissionSpecCard {
  createdAt: Date;
  updatedAt: Date;
  title: string;
  mid: string;
  status: MissionSpecStatus;
  platformId?: string;
  platformStatus?: MissionStatus;
  roles: MissionSpecCardRole[];
  applyStatus?: MissionApplyStatus;
  proposals: {
    id: string;
    createdAt: string; // DateISOString
    adminShouldReview: boolean;
    clientShouldReview: boolean;
  }[];
}

export interface MissionSpecPublishPayload {
  mid: string;
  hubspotDealId?: string;
}
