import { ProposalSummaryBuilder } from "models/Proposal";

export interface Attendee {
  email: string;
  name: string;
  timeZone: string;
  locale: string;
}

export enum InterviewBookingStatus {
  ACCEPTED = "ACCEPTED",
  PENDING = "PENDING",
  CANCELLED = "CANCELLED",
}

// Booking interface from Cal.com
export interface InterviewBooking {
  id: number;
  userId: number;
  description: string;
  uid: string;
  startTime: string;
  endTime: string;
  attendees: Attendee[];
  user: Attendee;
  status: InterviewBookingStatus;
  recurringEventId: string | null;
}

// Row interface for the table
export interface InterviewRow extends InterviewBooking {
  proposalId: string;
  roleTitle: string;
  builder: ProposalSummaryBuilder;
  feedback?: InterviewFeedback;
  transcripts?: InterviewTranscript[];
}

export interface InterviewFeedback {
  action: FeedbackAction;
  note?: string;
  author?: string;
  at?: Date;
  followUp?: FollowUpInterviewPayload;
}

export interface InterviewTranscript {
  recordingId: string;
  jobId: string;
  mediaKey?: string;
}

export interface InterviewTranscriptData {
  _id: string;
  mediaPresignedUrl: string;
  transcriptPresignedUrl: string;
}

export interface Interview {
  bookingId: number;
  booking: InterviewBooking;
  feedback?: InterviewFeedback;
  transcripts?: InterviewTranscript[];
}

export interface FollowUpInterviewPayload {
  interviewerId: string;
  roleTitle: string;
}

export interface SubmitFeedbackPayload {
  proposalId?: string;
  builderId?: string;
  bookingUid: string;
  feedback: InterviewFeedback;
}

export enum InterviewStatus {
  BOOKED = "booked",
  COMPLETED = "completed",
  CANCELLED = "cancelled",
}

export enum FeedbackAction {
  Hire = "Hire",
  BookFollowUpInterview = "Book a follow up interview",
  NeedMoreTime = "I need more time",
  NoHire = "No hire",
}

export type InterviewModalMode = "single" | "multiple";
