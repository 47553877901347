/** @jsxImportSource @emotion/react */

import { Column, Icon, Input, Row, Typography } from "@a_team/ui-components";
import ErrorMessage from "components/ErrorMessage";
import TrackModal from "components/TrackModal";
import { useFormik } from "formik";
import { useAdminAddAccount } from "queries/accounts/useAdminAddAccount";
import { FC } from "react";
import initialValues from "./initialValues";
import validationSchema from "./validationSchema";

import TrackButton from "components/TrackButton";
import { stringifyDate } from "helpers/time";
import useAccountAdvisor from "hooks/useAccountAdvisor";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import useDebounce from "hooks/useDebounce";
import Account, { AccountWithMembers } from "models/Account";
import { useGetAccountDuplicate } from "queries/admin/useGetAccountDuplicate";
import { useRootStore } from "store";

const getCSSRules: CSSRulesResolver = ({ colors }) => ({
  root: {
    overflow: "hidden",
  },
  title: {
    paddingTop: 1,
    marginBottom: 3,
  },
  submitRow: { paddingTop: 20 },
  alignRight: {
    marginLeft: "auto",
  },
  form: {
    paddingTop: 11,
    "& .input-label": {
      margin: "0 0 7px",
    },
  },
  duplicateWarning: {
    borderRadius: 4,
    marginTop: 8,
    backgroundColor: colors.Baracus[100],
    padding: 12,
  },
  callout: {
    display: "flex",
    flexDirection: "row",
    gap: 16,
    margin: "20px auto",
    background: colors.Baracus[200],
    color: colors.Baracus[900],
    padding: 16,
    borderRadius: 8,
  },
});

export interface AdminNewAccountModalProps {
  onCreated?(account: Account): void;
  onClose(): void;
  isOpen: boolean;
}

const DuplicateAccountWarning: FC<{ account?: AccountWithMembers | null }> = ({
  account,
}) => {
  const styles = useCSSRulesWithTheme(getCSSRules);

  if (!account) {
    return null;
  }

  const advisor = useAccountAdvisor(account.id);
  const companyUser = account.members[0] ? account.members[0] : null;
  const author = advisor || companyUser;

  const authorEmail = author ? author.user.email : "Unknown";
  const createdAt = account.createdAt
    ? ` on ${stringifyDate(account.createdAt)}`
    : "";

  return (
    <div css={styles.duplicateWarning}>
      <Typography component={"p"} variant="textSmall" weight="regular">
        A workspace for this company already exists. It was created by{" "}
        {authorEmail}
        {createdAt}. By proceeding to add client, you'll create an additional
        workspace for this client.
      </Typography>
    </div>
  );
};

const AdminNewAccountModal: FC<AdminNewAccountModalProps> = ({
  onCreated,
  ...rest
}) => {
  const {
    userStore: { isAdmin },
  } = useRootStore();
  const styles = useCSSRulesWithTheme(getCSSRules);
  const {
    mutate: addAccount,
    error,
    isLoading,
  } = useAdminAddAccount({
    onSuccess: onCreated,
  });

  const handleCreateAccount = async () => {
    addAccount(formik.values);
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleCreateAccount,
    validationSchema,
  });

  const duplicatePayload = useDebounce(formik.values, 300);
  const { data: duplicateAccount, isFetching: checkingDuplicates } =
    useGetAccountDuplicate({
      name: duplicatePayload.companyName,
      website: duplicatePayload.companyWebsite,
    });

  const hasError = (fieldName: keyof typeof formik.errors) => {
    if (!formik.touched[fieldName]) {
      return undefined;
    }

    return !!formik.errors[fieldName];
  };

  return (
    <TrackModal css={styles.root} name={"Admin new account modal"} {...rest}>
      <Typography
        component={"div"}
        css={styles.title}
        variant="textLarge"
        weight="bold"
      >
        Get started with a new client
      </Typography>

      <Typography component={"p"} variant="textMedium" weight="regular">
        Clients need to be added before you can create missions on their behalf.
        This information can always be updated later in settings.
      </Typography>

      {isAdmin && (
        <div css={styles.callout}>
          <Icon name="details" color="Baracus@800" size="lg" />
          <Typography component={"p"} variant="textSmall" weight="regular">
            The website associates the workspace with a company. If this client
            does not have a website, use <strong>https://unknown.com</strong>.
          </Typography>
        </div>
      )}

      <form css={styles.form} onSubmit={formik.handleSubmit}>
        <Input
          size="stretch"
          placeholder="Type your answer..."
          label="Company name"
          required={true}
          name="companyName"
          errorText={formik.errors.companyName}
          value={formik.values.companyName}
          error={hasError("companyName")}
          limit={32}
          onChange={formik.handleChange}
        />
        <Input
          size="stretch"
          placeholder="https://company.com..."
          label="Company website"
          name="companyWebsite"
          required={true}
          value={formik.values.companyWebsite}
          error={hasError("companyWebsite")}
          errorText={formik.errors.companyWebsite}
          onChange={formik.handleChange}
          onFocus={() => {
            if (!formik.values.companyWebsite) {
              formik.setFieldValue("companyWebsite", "https://");
            }
          }}
        />
        <DuplicateAccountWarning account={duplicateAccount} />
        <Row align="center" css={styles.submitRow}>
          <Column sm={8} xs={12}>
            <ErrorMessage
              error={error ? { ...error, show: true } : undefined}
            />
          </Column>
          <Column sm={4} xs={12}>
            <TrackButton
              name="Add workspace"
              disabled={isLoading || checkingDuplicates}
              css={styles.alignRight}
              size="md"
              loading={isLoading || checkingDuplicates}
              onClick={formik.submitForm}
            >
              Add workspace
            </TrackButton>
          </Column>
        </Row>
      </form>
    </TrackModal>
  );
};
export default AdminNewAccountModal;
