import { AuthStore } from "../store/Auth";
import serviceFetch from "./helpers";
import {
  NetPromoterScoreCanCreatePayload,
  NetPromoterScorePayload,
} from "models/NetPromoterScore";

const path = "net-promoter-score";

export const canCreateNetPromoterScore = async (
  auth: AuthStore,
  payload: NetPromoterScoreCanCreatePayload
): Promise<boolean> => {
  return serviceFetch(
    auth,
    `${path}/can-create/${payload.stage}`,
    null,
    "get",
    null
  );
};

export const createNetPromoterScore = async (
  auth: AuthStore,
  payload: NetPromoterScorePayload
): Promise<void> => {
  return serviceFetch(auth, `${path}`, null, "post", payload);
};
